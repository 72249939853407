@font-face {
    font-family: 'OpenSans';
    src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}


@font-face {
    font-family: 'OpenSansLight';
    src: url('fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('fonts/OpenSans-Bold.ttf') format('truetype');
}