:root{
    --green-color: #bbcf33;
    --blue-color: #14387F;
    --blue-color-overlay: rgba(3, 55, 123, 0.8);
}
body{
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    font-family: OpenSans;
}
.background-wrapper{
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
}
.languages{
    position: absolute;
    bottom: 1.5vh;
    left: 3.4vh;
    display: flex;
    align-items: center;
    z-index: 20;
}
.languages button{
    background-color: transparent;
    border: none;
    outline: none;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    color: white;
    font-size: 3vh;
    display: flex;
    align-items: center;
}
.languages button:after{
    content: "";
    display: block;
    width: 0.35vh;
    height: 3.15vh;
    background-color: white;
    margin: 1vh;
}
.languages button:last-child:after{
    display: none;
}
.languages button.active{
    color: var(--green-color);
}
.contentPart{
    padding: 6vh;
}
.contentPart .pageTitle {
    margin-top: -0.55vh;
    padding: 0;
    width: 50vh;
    color: white;
    font-size: 5.6vh;
    line-height: 115%;
    font-family: OpenSansBold;
}
.pageSubtitle{
    margin: 0;
    padding: 0;
    color: var(--green-color);
    margin-top: 2.5vh;
    font-size: 2.9vh;
    line-height: 100%;
    position: relative;
    display: inline-block;
    letter-spacing: 0.5px;
    font-family: 'OpenSansBold';
}
.legend-icon{
    position: absolute;
    right: -7.8vh;
    width: 5.6vh;
    height: auto;
    transform: translateY(-1vh);
}
.hexagon-wrap{
    width: 24vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}
.hexagon-bg{
    width: 100%;
    padding-top: 95%;
    background-color: rgba(198, 198, 198, 0.8);
}
.hexagon-title{
    position: absolute;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-color);
    text-align: center;
    font-size: 2.5vh;
    line-height: 110%;
    hyphens: auto;
    padding: 1vh;
    font-family: OpenSansLight;
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}
.hexagon-0{
    position: absolute;
    bottom: 7.7vh;
    left: 14.7vw;
}
.hexagon-1{
    position: absolute;
    bottom: 19.9vh;
    left: 26.1vw;
}
.hexagon-2{
    position: absolute;
    bottom: 7.7vh;
    left: 37.6vw;
}
.hexagon-3{
    position: absolute;
    bottom: 19.9vh;
    left: 49vw;
}
.hexagon-4{
    position: absolute;
    bottom: 7.7vh;
    left: 60.6vw;
}
.hexagon-5{
    position: absolute;
    bottom: 19.85vh;
    left: 71.9vw;
}
.overlay{
    position: absolute;
    width: 71.3vw;
    height: 86vh;
    top: 10vh;
    left: 14.5vw;
    z-index: 10;
}
.overlay .background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blue-color-overlay);
    filter: blur(3vh);
}
.overlay .closeOverlay{
    position: absolute;
    right: 3.1vh;
    top: 3.1vh;
    z-index: 2;
}
.overlay .closeOverlay img{
    width: 5vh;
    height: auto;
}
.overlayContent{
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10vh 9vh 15vh 9vh;
}
.overlay-text{
    height: 100%;
    overflow-y: auto;
}
.inner-text-ce{
    padding: 7vh 17vh 3vh 17vh;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}
.innerContent-wrap{
    overflow: hidden;
}
.innerContent-wrap, .innerContent-inner{
    width: 100%;
    height: 100%;
    position: relative;
}
.innerContent-inner{
    transition: 0.5s;
}
.innerContent-innerswipable{
    transition: 0.5s;
}
.innerContent-innerswipable{
    height: 100%;
}
.innerContent-innerswipable > div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.overlay-text h3{
    color: var(--green-color);
    font-size: 4.5vh;
    padding: 0px;
    margin: 0 0 3.3vh 0;
    line-height: 120%;
    font-family: 'OpenSansBold';
}
.overlay-text .text{
    color: white;
    font-size: 2.2vh;
    line-height: 150%;
}
.side-arrows{
    position: absolute;
    top: 37.5vh;
}
.side-arrows img{
    width: 5vh;
}
.side-arrows.previous{
    left: 2.1vh;
}
.side-arrows.next{
    right: 2.1vh;
}
.pagination{
    position: absolute;
    bottom: 11.5vh;
    height: 1vh;
    width: 100%;
    left: 0px;
    display: flex;
    justify-content: center;
}
.pagination .pagination-item{
    width: 5.05vh;
    height: 100%;
    background-color: #8ea3be;
    margin: 0 0.5vh
}
.pagination .pagination-item.active{
    background-color: var(--green-color);
}
.iframe-wrap{
    position: relative;
    width: 100%;
    padding-top: 54%;
}
.iframe-wrap iframe, .iframe-wrap .videoFrame, .iframe-wrap .image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iframe-wrap .image img{
    width: 100%;
    height: auto;
}
.has_caption .iframe-wrap .image img, .iframe-wrap iframe, .iframe-wrap video{
    width: auto;
    height: auto;
    max-height: 57vh;
}
.has_caption .imageCaption{
    margin-top: 1vh;
    color: white;
    font-size: 2vh;
    align-self: flex-start;
}
.imageCaption caption{
    display: block;
    text-align: left;
}
/* width */
::-webkit-scrollbar {
    width: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0.5vh;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--green-color);
    border-radius: 0.5vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--green-color);
}
.play-button{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 50;
}
.play-button.hidden{
    display: none;
}
.play-button svg{
    transform: translate(-50%, -50%);
    width: 15vh;
    height: 15vh;
    color: var(--green-color);
}
.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.25s 0.25s, height 0.25s 0.25s, opacity 0.25s;

}
.fadeIn{
    opacity:1;
    transition: width 0.25s, height 0.25s, opacity 0.25s 0.25s;
}
.inner-text-ce img{
    max-width: 100%;
    height: auto;
}